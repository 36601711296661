/*-----------------------------------------------------------------
- User: Extra cost Services
-----------------------------------------------------------------*/
import { Resource } from '../index';

export default {
    /**
     * Extra cost - Create a new resource
     *
     * @param {...any} args
     * @returns {*}
     */
    create: (...args: any[]): any => Resource.post('extra_cost', args),

    /**
     * Extra cost - list
     *
     * @param {...any} args
     * @returns {*}
     */
    list: (...args: any[]): any => Resource.get('extra_cost', args),

    /**
     * Extra cost - Edit a resource
     *
     * @param {...any} args
     * @returns {*}
     */
    edit: ({ id, ...args }: any): any => Resource.patch(`extra_cost/${id}`, [args]),

    /**
     * Extra cost - Delete a resource
     *
     * @param {...any} args
     * @returns {*}
     */
    delete: ({ id, ...args }: any): any => Resource.delete(`extra_cost/${id}`, [args]),
};
