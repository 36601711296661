import React from 'react';
import { NavLink } from 'react-router-dom';

interface props {}

/**
 * Navigation implementation
 */
export const Navigation: React.FC<props> = () => {
    const navList = [
        {
            order: 200,
            icon : 'fa-solid fa-list-check',
            title: 'TODOs',
            link : '/todo-list',
        },
        {
            order: 200,
            icon : 'fa-solid fa-bag-shopping',
            title: 'Shopping',
            link : '/shopping',
        },
        {
            order: 200,
            icon : 'fa-solid fa-home',
            title: 'Rooms',
            link : '/',
        },
        {
            order: 200,
            icon : 'fa-regular fa-calendar',
            title: 'Calendar',
            link : '/general-calendar',
        },
        {
            order: 200,
            icon : 'fa-solid fa-plane',
            title: 'Booking',
            link : '/add-reservation',
        },
    ];

    return (
        <nav className="navigation-bar">
            {
                navList.sort((a, b) => a.order - b.order).map((nav) => (
                    <NavLink
                        className={ ({ isActive }) => (isActive ? 'active' : 'inactive') }
                        to={ nav.link }
                        key={ nav.link }
                    >
                        <div className="content">
                            <i className={ nav.icon } />
                            <span>
                                { nav.title }
                            </span>
                        </div>
                    </NavLink>
                ))
            }
        </nav>
    );
};
