import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import {
    Form, Button, Modal, ListGroup,
} from 'react-bootstrap';
import { $services } from '../../../services';
import { errorHandler } from '../../../services/helpers';
import { $store } from '../../../store';
import Http from '../../../services/Http';
import '../scss/auth.scss';

/**
 * Login implementation
 */
export const Login: React.FC = () => {
    const [showSelectModal, setShowSelectModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [residenceList, setResidenceList] = useState([]);
    const [model, setModel] = useState({
        username: '',
        password: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        const { isLoggedIn } = $store.getState().auth;
        if (isLoggedIn) {
            navigate('/');
        }
    }, []);

    /**
     * Do login action
     * 
     * @param {*} event
     */
    const login = (event: any) => {
        event.preventDefault();
        setLoading(true);
        $services.Auth.login(model).then((response: any) => {
            Http.defaults.headers.common.Authorization = `Bearer ${response.data.access}`;

            $services.Residence.list().then((responseResidence: any) => {
                if (responseResidence.data.length > 1) {
                    setResidenceList(responseResidence.data);
                    setShowSelectModal(true);
                } else {
                    $store.dispatch({ type: 'auth/login', payload: response.data });

                    navigate('/');
                }
            }).catch((error: any) => {
                errorHandler(error);
            });
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setLoading(false);
        });
    };


    /**
     * Select residence
     * 
     * @param {*} event
     */
    const submit = (event: any) => {
        event.preventDefault();
        setLoading(true);
        $services.Auth.login(model).then((response: any) => {
            $store.dispatch({ type: 'auth/login', payload: response.data });

            navigate('/');
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <div className="m-auth">
            <Helmet>
                <title>Login</title>
            </Helmet>

            <img
                src="/images/login.png"
                alt="Residence"
            />

            <Form onSubmit={ login }>
                <Form.Group
                    className="mb-3"
                    controlId="formBasicUsername"
                >
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Username"
                        onChange={ (event: any) => {
                            setModel((state) => ({
                                ...state,
                                username: event.target.value,
                            }));
                        } }
                    />
                </Form.Group>

                <Form.Group
                    className="mb-3"
                    controlId="formBasicPassword"
                >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={ (event: any) => {
                            setModel((state) => ({
                                ...state,
                                password: event.target.value,
                            }));
                        } }
                    />
                </Form.Group>
                <Button
                    variant="primary"
                    type="submit"
                    disabled={ isLoading }
                    size="lg"
                >
                    {isLoading ? 'Loading…' : 'Submit'}
                </Button>
            </Form>


            {/* Select modal */}
            <Modal
                show={ showSelectModal }
                onHide={ () => setShowSelectModal(false) }
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Residence</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup defaultActiveKey="#link1">
                        {
                            residenceList.map((residence: any) => (
                                <ListGroup.Item
                                    className="d-flex justify-content-between align-items-start"
                                    action
                                    key={ residence.id }
                                    onClick={ () => {
                                        setModel((state) => ({
                                            ...state,
                                            residence_id: residence.id,
                                        }));
                                    } }
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{ residence.name }</div>
                                        { residence.description }
                                    </div>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={ () => setShowSelectModal(false) }
                        disabled={ isLoading }
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={ submit }
                        disabled={ isLoading }
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
