/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';


const initialState: any = {
    isLoading: false,
};

export const loadingSlice = createSlice({
    name: 'loading',

    initialState,

    reducers: {
        /**
         * Start loading
         *
         * @param {*} state - store state
         */
        start(state: any): any {
            state.isLoading = true;
        },

        /**
         * Stop loading
         *
         * @param {*} state - store state
         */
        end(state: any): any {
            state.isLoading = false;
        },
    },
});

export const {
    start,
    end,
} = loadingSlice.actions;

export default loadingSlice.reducer;
