import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import authReducer from './auth';
import loadingReducer from './loading';

const reducers = combineReducers({
    auth   : authReducer,
    loading: loadingReducer,
});

const persistConfig = {
    key      : process.env.REACT_APP_REDUX_KEY || 'resistance',
    storage,
    whitelist: ['auth', 'loading'],
};

export const store = configureStore({
    reducer   : persistReducer(persistConfig, reducers),
    devTools  : process.env.NODE_ENV !== 'production',
    middleware: [thunk],
});

export { store as $store };

export type RootState = ReturnType<typeof store.getState>
