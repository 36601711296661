/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import Http from '../services/Http';

const initialState: { token: string, isLoggedIn: Boolean } = {
    token     : localStorage.getItem('token') || '',
    isLoggedIn: false,
};

export const authSlice = createSlice({
    name: 'auth',

    initialState,

    reducers: {
        /**
         * Login user
         *
         * @param {*} state - store state
         * @param {*} action - action
         */
        login(state: any, action: any): any {
            Http.defaults.headers.common.Authorization = `Bearer ${action.payload.access}`;

            state.token = action.payload.access;
            state.isLoggedIn = true;

            localStorage.setItem('token', action.payload.access);
        },

        /**
         * Logout user
         *
         * @param {*} state - store state
         */
        logout(state: any): any {
            state.token = '';
            state.isLoggedIn = false;

            localStorage.removeItem('token');
        },
    },
});

export const {
    login,
    logout,
} = authSlice.actions;

export default authSlice.reducer;
