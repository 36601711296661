/*-----------------------------------------------------------------
- Helpers
-----------------------------------------------------------------*/
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { $store } from '../store';

/**
 * Return api action url
 *
 * @param {string} action - http action
 * @returns {string}
 */
export const apiActionUrl = (action: string): string => `/${action}`;

/**
 * Return api base domain
 *
 * @returns {string}
 */
export const apiBaseDomain = (): string => {
    let domain = `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}`; // eslint-disable-line

    if (process.env.NODE_ENV === 'production') {
        domain = process.env.REACT_APP_API || domain;
    }

    return domain.replace(/\/?$/, '/');
};

/**
 * Return api base url
 *
 * @returns {string}
 */
export const apiBaseUrl = (): string => apiBaseDomain() + '/api/'.replace(/^\/?/, '');

/**
 * Handle response error
 *
 * @param {object} responseError
 */
export const errorHandler = (responseError: {[k: string]: any}) => {
    const statusCode = get(responseError, 'response.status');
    const response = get(responseError, 'response.data', {});

    // UnAuthorized status code
    if (statusCode === 401) {
        $store.dispatch({ type: 'auth/logout' });

        window.location.href = `${window.location.origin}/auth/login`;
    } else if (statusCode?.toString()?.startsWith(4)) {
        const { error } = response;

        error.forEach((item: any) => {
            toast.error(item, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        });
    } else if (statusCode?.toString()?.startsWith(5)) {
        toast.error('We have problems on server 🚶', {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    }
};
