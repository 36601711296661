/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
    Button, Col, Row, Form, Modal,
} from 'react-bootstrap';
import { NumericFormat, PatternFormat } from 'react-number-format';
import DatePicker from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import weekends from 'react-multi-date-picker/plugins/highlight_weekends';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { digits, formatPrice } from '../../../../helpers/number';
import { $services } from '../../../../services';
import { errorHandler } from '../../../../services/helpers';
import './index.scss';

interface props {
    open: boolean;
    close: () => void;
    content: {[k: string]: any}
    pricesResource: {
        per_person: 0,
        additions : 0,
    }
}

/**
 * Edit Reservation implementation
 */
export const EditReservation: React.FC<props> = ({
    open = false, close, content, pricesResource = {
        per_person: 0,
        additions : 0,
    },
}) => {
    const [isLoading, setLoading] = useState(false);
    const [model, setModel] = useState(content);
    const [nightCount, setNightCount] = useState<any>(0);

    /**
     * Submit reservation form
     */
    const submitForm = () => {
        setLoading(true);
        $services.Reservation.edit({
            ...model,
            phone_number  : model.phone_number.toString().replaceAll(' ', ''),
            payable_amount: model.payable_amount.toString().replace(/,/g, ''),
        }).then((response: any) => {
            close();
            toast.success('Reserve has been submitted.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Modal
            show={ open }
            onHide={ () => close() }
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit reservation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="edit-reservation-modal">
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Start date ~ End date:
                            </Form.Label>
                            <DatePicker
                                calendar={ persian }
                                locale={ persian_fa }
                                plugins={ [weekends()] }
                                range
                                value={ [
                                    model.start_date.replaceAll('-', '/'),
                                    model.end_date.replaceAll('-', '/'),
                                ] }
                                calendarPosition="bottom-right"
                                onChange={ (event: any) => {
                                    if (event.length === 2) {
                                        const days = get(event, '1.dayOfYear') - get(event, '0.dayOfYear') + 1;
                                        setNightCount(days);
                                        setModel((state: any) => ({
                                            ...state,
                                            start_date    : digits(event[0]?.toDate().toLocaleDateString('fa-IR').replaceAll('/', '-')),
                                            end_date      : digits(event[1]?.toDate().toLocaleDateString('fa-IR').replaceAll('/', '-')),
                                            payable_amount: ((model.number_of_guest * pricesResource.per_person)
                                            + (model.number_of_extra_guest * pricesResource.additions)) * days,
                                            total_price: ((model.number_of_guest * pricesResource.per_person)
                                            + (model.number_of_extra_guest * pricesResource.additions)) * days,
                                        }));
                                    }
                                } }
                                mapDays={ ({
                                    date, today, isSameDate, selectedDate,
                                }: any) => {
                                    const prop: any = {};
    
                                    if (date.dayOfBeginning < today.dayOfBeginning) {
                                        return { disabled: true };
                                    }
    
                                    if (isSameDate(date, selectedDate)) {
                                        prop.style = {
                                            ...prop.style,
                                            backgroundColor: '#FFFFFF',
                                            color          : '#424242',
                                        };
                                    }
    
                                    return prop;
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Guests number:
                            </Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Guests number"
                                defaultValue={ model.number_of_guest }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        number_of_guest: event.target.value,
                                        payable_amount : ((event.target.value * pricesResource.per_person)
                                    + (model.number_of_extra_guest * pricesResource.additions)) * nightCount,
                                        total_price: ((event.target.value * pricesResource.per_person)
                                    + (model.number_of_extra_guest * pricesResource.additions)) * nightCount,
                                    }));
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Additional Guests:
                            </Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Additional Guests"
                                defaultValue={ model.number_of_extra_guest }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        number_of_extra_guest: event.target.value,
                                        payable_amount       : ((model.number_of_guest * pricesResource.per_person)
                                    + (event.target.value * pricesResource.additions)) * nightCount,
                                        total_price: ((model.number_of_guest * pricesResource.per_person)
                                    + (event.target.value * pricesResource.additions)) * nightCount,
                                    }));
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                First Name:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={ model.first_name }
                                placeholder="First Name"
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        first_name: event.target.value,
                                    }));
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Last Name:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Last Name"
                                defaultValue={ model.last_name }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        last_name: event.target.value,
                                    }));
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                National code:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={ model.national_code }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        national_code: event.target.value,
                                    }));
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Phone number:
                            </Form.Label>
                            <PatternFormat
                                className="form-control"
                                format="#### ### ####"
                                allowEmptyFormatting
                                defaultValue={ model.phone_number }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        phone_number: event.target.value,
                                    }));
                                } }
                            />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Payable amount:
                            </Form.Label>
                            <NumericFormat
                                className="form-control"
                                thousandSeparator=","
                                allowLeadingZeros
                                value={ model.payable_amount }
                                onChange={ (event: any) => {
                                    setModel((state: any) => ({
                                        ...state,
                                        payable_amount: event.target.value,
                                    }));
                                } }
                            />
                            <Form.Text>
                                This can be part of the total cost or all of it. (like Deposit)
                            </Form.Text>
                        </div>
                    </Col>
                    <Col md="12">
                        <Button
                            variant="light"
                            disabled
                            className="cost w-100"
                        >
                            <span>
                                { formatPrice(model.total_price) }
                            </span>
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={ () => close() }
                    disabled={ isLoading }
                >
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={ submitForm }
                    disabled={ isLoading }
                >
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
