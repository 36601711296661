/*-----------------------------------------------------------------
- User: Residence Services
-----------------------------------------------------------------*/
import { Resource } from '../index';

export default {
    /**
     * Residence - list
     *
     * @param {...any} args
     * @returns {*}
     */
    list: (...args: any[]): any => Resource.get('residence', args),

    /**
     * Residence - Create a new resource
     *
     * @param {...any} args
     * @returns {*}
     */
    create: (...args: any[]): any => Resource.post('residence', args),
};
