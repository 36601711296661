/*-----------------------------------------------------------------
- Reservation Services
-----------------------------------------------------------------*/
import { Resource } from '../index';

export default {
    /**
     * Reservation - list
     *
     * @param {...any} args
     * @returns {*}
     */
    list: (...args: any[]): any => Resource.get('reservation', args),

    /**
     * Reservation - Create a new resource
     *
     * @param {...any} args
     * @returns {*}
     */
    create: (...args: any[]): any => Resource.post('reservation', args),

    /**
     * Reservation - edit
     *
     * @param {...any} args
     * @returns {*}
     */
    edit: ({ id, ...args }: any): any => Resource.patch(`reservation/${id}`, [args]), // eslint-disable-line camelcase

    /**
     * Reservation - detail
     *
     * @param {...any} args
     * @returns {*}
     */
    detail: ({ reservation_id, ...args }: any): any => Resource.get(`reservation/detail/${reservation_id}`, [args]), // eslint-disable-line camelcase

    /**
     * Reservation - delete
     *
     * @param {...any} args
     * @returns {*}
     */
    delete: ({ id, ...args }: any): any => Resource.delete(`reservation/detail/${id}`, [args]),
};
