import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { Login } from '../../modules/auth/views/Login';
import { Dashboard } from '../../modules/dashboard/views/Dashboard';
import { AddReservation } from '../../modules/dashboard/views/AddReservation';
import { RoomSingle } from '../../modules/dashboard/views/RoomSingle';
import { Shopping } from '../../modules/dashboard/views/Shopping';
import { GeneralCalendar } from '../../modules/dashboard/views/Calendar';
import { HelmetMetaTags } from '../HelmetMetaTags';
import { Todo } from '../../modules/dashboard/views/Todo';

// eslint-disable-next-line require-jsdoc
export const Index: React.FunctionComponent = () => (
    <HelmetProvider>

        {/* Meta tags */}
        <HelmetMetaTags />

        {/* Main Content */}
        <main className="main-layout">
            <Routes>
                <Route path="/auth/login" element={ <Login /> } />
                <Route path="/" element={ <Dashboard /> } />
                <Route path="/add-reservation" element={ <AddReservation /> } />
                <Route path="/room-single/:id/:name" element={ <RoomSingle /> } />
                <Route path="/general-calendar" element={ <GeneralCalendar /> } />
                <Route path="/shopping" element={ <Shopping /> } />
                <Route path="/todo-list" element={ <Todo /> } />
            </Routes>

            <ToastContainer />
        </main>
    </HelmetProvider>
);
