import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import manifest from './controller/manifest';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

/**
 * Add manifest information in app
 */
(() => {
    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);

    const exist = document.querySelector('head > link[rel="manifest"]');
    if (exist) {
        exist.setAttribute('href', manifestURL);
    } else {
        const link = document.createElement('link');
        link.rel = 'manifest';
        link.href = manifestURL;
        (document.querySelector('head') as HTMLElement).appendChild(link);
    }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
