/*-----------------------------------------------------------------
- User: Auth Services
-----------------------------------------------------------------*/
import { Resource } from '../index';

export default {
    /**
     * Auth - Login
     *
     * @param {...any} args
     * @returns {*}
     */
    login: (...args: any[]): any => Resource.post('login', args),
};
