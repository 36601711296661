/*-----------------------------------------------------------------
- Resources
-----------------------------------------------------------------*/
import Http from './Http';
import { apiActionUrl } from './helpers';

export default {
    /**
     * Return get request of axios
     *
     * @param {string} endpoint
     * @param {...args} args
     * @returns {*}
     */
    get(endpoint: string, args: any[]): any {
        const params = args[0] || {};
        return Http.get(apiActionUrl(endpoint), { params });
    },

    /**
     * Return post request of axios
     *
     * @param {string} endpoint
     * @param {...args} args
     * @returns {*}
     */
    post(endpoint: string, args: any[]): any {
        const params = args[0] || {};
        return Http.post(apiActionUrl(endpoint), params, {});
    },

    /**
     * Return delete request of axios
     *
     * @param {string} endpoint
     * @param {...args} args
     * @returns {*}
     */
    delete(endpoint: string, args: any[]): any {
        const params = args[0] || {};
        return Http.delete(apiActionUrl(endpoint), { params });
    },

    /**
     * Return patch request of axios
     *
     * @param {string} endpoint
     * @param {...args} args
     * @returns {*}
     */
    patch(endpoint: string, args: any[]): any {
        const params = args[0] || {};
        return Http.patch(apiActionUrl(endpoint), params);
    },

    /**
     * Default Axios instance to use directly
     */
    Http,
};
