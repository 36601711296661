/*-----------------------------------------------------------------
- User: Room Services
-----------------------------------------------------------------*/
import { Resource } from '../index';

export default {
    /**
     * Room - list
     *
     * @param {...any} args
     * @returns {*}
     */
    list: (...args: any[]): any => Resource.get('room', args),

    /**
     * Room - Create a new resource
     *
     * @param {...any} args
     * @returns {*}
     */
    create: (...args: any[]): any => Resource.post('room', args),
};
