/*-----------------------------------------------------------------
- Load all services
-----------------------------------------------------------------*/
import { deepmerge } from 'deepmerge-ts';
import Resource from './Resource';

// Default object
let all: {[k: string]: any} = {};
// Load files
const importedFiles: any = require.context('./resources', true, /\.(ts)$/);

// Merge services
const importedServices : any = {};
importedFiles.keys().forEach((fileName: string) => {
    const file = fileName.replace(/(\.\/|Services)/g, '').replace(/.ts/, '');

    importedServices[file] = {
        ...(importedServices[file] ? importedServices[file] : importedFiles(fileName).default),
    };
});
all = deepmerge(all, importedServices);

// Export all services to use directly
const $services: {[k: string]: any} = {
    ...all,
    ...Resource,
};

export {
    $services,
    Resource,
};
