import React from 'react';
import { Link } from 'react-router-dom';
import { digits, separate } from '../../../../helpers/number';

interface props {
    content: {[k: string]: any}
}

/**
 * Room Card implementation
 */
export const RoomCard: React.FC<props> = ({ content }) => (
    <Link
        className="c-room-card"
        to={ `/room-single/${content.id}/${encodeURIComponent(JSON.stringify({
            name                 : content.name,
            cost                 : content.cost,
            additional_guest_cost: content.additional_guest_cost,
        }))}` }
    >
        <img
            src="/images/room.png"
            alt={ content.name }
            className="c-room-card__image"
        />

        { content.name
            && <p className="c-room-card__title">{ content.name }</p> }

        { content.description
            && <p className="c-room-card__description">{ content.description }</p>}

        { content.cost
            && (
                <p className="c-room-card__cost">
                    { `قیمت: ${digits(separate(content.cost), 'fa')} + ${digits(separate(content.additional_guest_cost), 'fa')} تومان` }
                </p>
            ) }
    </Link>
);
