/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import moment from 'jalali-moment';
import { NumericFormat } from 'react-number-format';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { Navigation } from '../components/Navigation/index';
import { today as TodayTime } from '../../../helpers/date';
import { Loading } from '../../../components/Loading';
import { $services } from '../../../services';
import { errorHandler } from '../../../services/helpers';
import { $store } from '../../../store';
import '../scss/dashboard.scss';

/**
 * Shopping implementation
 */
export const Shopping: React.FC = () => {
    const [isLoading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [reservationId, setReservationId] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [roomList, setRoomList] = useState([]);
    const [model, setModel] = useState({
        reservation_id: '',
        type          : '',
        cost          : 0,
    });
    const navigate = useNavigate();

    /**
     * Fetch reservation list
     */
    const fetchCalendarDates = (room_id: string = get(roomList, '0.id', '')) => {
        setLoading(true);
        $services.Calendar.list({
            room_id,
            day  : moment().locale('fa').format('DD'),
            month: moment().locale('fa').format('M'),
            year : moment().locale('fa').format('YYYY'),
        }).then((response: any) => {
            const find = response.data.find((d: any) => d.date.toString() === moment().locale('fa').format('DD').toString())?.reservation_id;
            setReservationId(find);
    
            setModel((state: any) => ({
                ...state,
                reservation_id: find,
            }));
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    /**
     * Submit shopping form
     */
    const submitForm = () => {
        setLoading(true);
        $services.ExtraCost.create({
            ...model,
            cost: model.cost.toString().replace(/,/g, ''),
        }).then(() => {
            toast.success('Your shopping has been submitted.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setModel((state: any) => ({
                ...state,
                type: '',
                cost: 0,
            }));
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    /**
     * Fetch room list
     */
    const fetch = () => {
        setFetchLoading(true);
        $services.Room.list().then((response: any) => {
            setRoomList(response.data);
            setModel((state: any) => ({
                ...state,
                room_id: response.data[0].id,
            }));
            fetchCalendarDates(response.data[0].id);
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setFetchLoading(false);
        });
    };

    useEffect(() => {
        const { isLoggedIn } = $store.getState().auth;
        if (isLoggedIn) {
            fetch();
        } else {
            navigate('/auth/login');
        }
    }, []);

    return (
        <Loading active={ fetchLoading }>
            <div className="m-dashboard m-dashboard__shopping">
                <Helmet>
                    <title>Shopping</title>
                </Helmet>

                <header>
                    <span className="today-time">{ TodayTime() }</span>

                    <h3 className="m-dashboard__title">Shopping 🛒</h3>
                </header>

                <Row className="m-dashboard__room-list">
                    <Col md="12">
                        <div className="form-section">
                            <Form.Label className="form-section__label">
                                Room:
                            </Form.Label>
                            <Form.Select
                                aria-label="Room select"
                                onChange={ (event: any) => {
                                    fetchCalendarDates(event.target.value);
                                } }
                            >
                                {
                                    roomList.map((room: any) => (
                                        <option
                                            value={ room.id }
                                            key={ room.id }
                                        >
                                            { room.name }
                                        </option>
                                    ) )
                                }
                            </Form.Select>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="form-section">
                            <InputGroup>
                                <InputGroup.Text>تومان</InputGroup.Text>
                                <NumericFormat
                                    className="form-control"
                                    thousandSeparator=","
                                    allowLeadingZeros
                                    disabled={ !reservationId }
                                    value={ model.cost || '' }
                                    placeholder="Price"
                                    onChange={ (event: any) => {
                                        setModel((state: any) => ({
                                            ...state,
                                            cost: event.target.value,
                                        }));
                                    } }
                                />
                                <Form.Control
                                    type="text"
                                    disabled={ !reservationId }
                                    placeholder="Item name"
                                    value={ model.type || '' }
                                    onChange={ (event: any) => {
                                        setModel((state: any) => ({
                                            ...state,
                                            type: event.target.value,
                                        }));
                                    } }
                                />
                            </InputGroup>
                        </div>
                    </Col>

                    { reservationId && (
                        <div className="suggests-list">
                            Suggests:
                            { ['شام', 'ناهار', 'سرویس چای', 'قهوه'].map((s :any) => (
                                <Badge
                                    bg="light"
                                    text="dark"
                                    key={ s }
                                    className="suggests-item"
                                    onClick={ (event: any) => {
                                        setModel((state: any) => ({
                                            ...state,
                                            type: event.target.innerText,
                                        }));
                                    } }
                                >
                                    {s}
                                </Badge>
                            ))}
                        </div>
                    )}

                    <Col md="12">
                        <Button
                            variant="primary"
                            disabled={ !reservationId || isLoading }
                            className="w-100"
                            onClick={ submitForm }
                        >
                            { isLoading ? 'Loading…' : 'Submit' }
                        </Button>

                        <Button
                            variant="warning"
                            className="w-100"
                            onClick={ () => {
                                setModel((state: any) => ({
                                    ...state,
                                    type: '',
                                    cost: 0,
                                }));
                            } }
                        >
                            Clear Form
                        </Button>
                    </Col>
                </Row>

                <Navigation />
            </div>
        </Loading>
    );
};
