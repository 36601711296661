import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './store';
import { MainLayout } from './components/layouts/MainLayout';
import { Index } from './components/pages';

import './assets/scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';

const persistor = persistStore(store);

// eslint-disable-next-line
const App = () => (
    <Provider store={ store }>
        <PersistGate persistor={ persistor }>
            <BrowserRouter>
                <MainLayout Content={ Index } />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

export default App;
