/* -------------------------------------------------------------------------- */
/* HELPERS: Number                                                            */
/* -------------------------------------------------------------------------- */
const numbers: {[k: string]: string[]} = {
    fa: ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'],
    ar: ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'],
    en: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
};

/**
 * Validate digit
 *
 * @param {null|false|undefined} digit
 * @returns {boolean}
 */
export const NotValidDigit = (digit: null|false|undefined): boolean => digit === null || digit === false || typeof digit === 'undefined';

/**
 * Converts English digits into asked locale formatted number
 *
 * @param {*} digit
 * @param {string} locale
 * @returns {string}
 */
export const digits = (digit: any, locale: 'fa' | 'en' | 'ar' = 'en'): string|number => {
    if (NotValidDigit(digit)) {
        return digit;
    }

    return `${digit}`.replace(/[0123456789]/g, (w) => numbers[locale][numbers.en.indexOf(w)]).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (w) => numbers[locale][numbers.fa.indexOf(w)]).replace(/[٠١٢٣٤٥٦٧٨٩]/g, (w) => numbers[locale][numbers.ar.indexOf(w)]);
};

/**
 * Separate number
 *
 * @param {string|number} number - number
 * @param {string} separator - separator symbol
 * @returns {string}
 */
export const separate = (number: string|number, separator: string = ','): string => number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

/**
 * Format phone number
 *
 * @param {string} phoneNumber - number
 * @returns {string}
 */
export const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
    const match = cleaned.match(/^(98|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = (match[1] ? '98 ' : '');
        return ['+', intlCode, ' ', match[2], ' ', match[3], ' ', match[4]].join('');
    }
    return '';
};

/**
 * Format price for show to user
 * 
 * @param {string|number} value - number
 * @returns {string}
 */
export const formatPrice = (value: number|string, removeThreeZero: boolean = true): string => {
    const price = separate(value.toString().replace(removeThreeZero ? '000' : '', ''));

    if (separate(price).split(',').length > 1) {
        if (separate(price).split(',')[1] === '000') {
            return `${separate(price).split(',')[0]} میلیون تومان`;
        }

        return `${separate(price).split(',')[0]} میلیون و ${separate(price).split(',')[1]} هزار تومان`;
    }

    return `${digits(separate(price), 'fa')} هزار تومان`;
};
