/*-----------------------------------------------------------------
- Http instance
-----------------------------------------------------------------*/
import axios from 'axios';
import { apiBaseUrl } from './helpers';

// Add token
const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

// Create axios instance
const axiosInstance: any = axios.create({
    baseURL: apiBaseUrl(),
});

// Create cancel token
axiosInstance.source = axios.CancelToken.source();

/**
 * Cancels every request
 */
axiosInstance.cancelRequests = () => {
    axiosInstance.source.cancel({
        isCanceled: true,
        message   : 'Requests canceled',
    });
    axiosInstance.source = axios.CancelToken.source();
};

/**
 * returns if error is being caused by axios cancel
 *
 * @param {*} err
 * @returns {boolean} - true if the error caused by canceling the request
 */
axiosInstance.areRequestsCanceled = (err: any): boolean => err && axios.isCancel(err);

export default axiosInstance;
