export default {
    name            : 'Hostess web app',
    short_name      : 'Hostess',
    description     : 'The residence management application',
    scope           : `${window.location.origin}/`,
    orientation     : 'any',
    background_color: '#ffffff',
    theme_color     : '#ffffff',
    start_url       : `${window.location.origin}/`,
    display         : 'standalone',
    dir             : 'ltr',
    lang            : 'en',
    categories      : [],
    shortcuts       : [
        {
            name       : 'Have you new Guests?',
            short_name : 'New Reservation',
            description: 'Add new booking time',
            url        : `${window.location.origin}/add-reservation?source=pwa`,
            icons      : [{ src: '/images/add-reservation.png', sizes: '96x96' }],
        },
        {
            name       : 'Do you want to see your calendar?',
            short_name : 'Calendar',
            description: 'View date information for plan your time',
            url        : `${window.location.origin}/general-calendar?source=pwa`,
            icons      : [{ src: '/images/calendar.png', sizes: '96x96' }],
        },
        {
            name       : 'Have you any buy items?',
            short_name : 'Shopping Cart',
            description: 'Add new item price information to guest order',
            url        : `${window.location.origin}/shopping?source=pwa`,
            icons      : [{ src: '/images/shopping.png', sizes: '96x96' }],
        },
        {
            name       : 'Are you busy?',
            short_name : 'TODO',
            description: 'Add or check your schedule',
            url        : `${window.location.origin}/todo-list?source=pwa`,
            icons      : [{ src: '/images/todo.png', sizes: '96x96' }],
        },
    ],
    icons: [
        {
            src  : 'favicon.ico',
            type : 'image/x-icon',
            sizes: 'any',
        },
        {
            src  : 'icons/web/tent-16.png',
            type : 'image/png',
            sizes: '16x16',
        },
        {
            src  : 'icons/web/tent-32.png',
            type : 'image/png',
            sizes: '32x32',
        },
        {
            src  : 'icons/web/tent-96.png',
            type : 'image/png',
            sizes: '96x96',
        },
        {
            src  : 'icons/android/tent-72.png',
            type : 'image/png',
            sizes: '72x72',
        },
        {
            src  : 'icons/android/tent-96.png',
            type : 'image/png',
            sizes: '96x96',
        },
        {
            src  : 'icons/android/tent-192.png',
            type : 'image/png',
            sizes: '192x192',
        },
        {
            src  : 'icons/android/tent-512.png',
            type : 'image/png',
            sizes: '512x512',
        },
        {
            src  : 'icons/ios/tent-57.png',
            type : 'image/png',
            sizes: '57x57',
            rel  : 'apple-touch-icon',
        },
        {
            src  : 'icons/ios/tent-60.png',
            type : 'image/png',
            sizes: '60x60',
            rel  : 'apple-touch-icon',
        },
        {
            src  : 'icons/ios/tent-72.png',
            type : 'image/png',
            sizes: '72x72',
            rel  : 'apple-touch-icon',
        },
        {
            src  : 'icons/ios/tent-76.png',
            type : 'image/png',
            sizes: '76x76',
            rel  : 'apple-touch-icon',
        },
    ],
};
