/*-----------------------------------------------------------------
- User: Calendar Services
-----------------------------------------------------------------*/
import { Resource } from '../index';

export default {
    /**
     * Calendar - list
     *
     * @param {...any} args
     * @returns {*}
     */
    list: (...args: any[]): any => Resource.get('calender', args),
};
