/* -------------------------------------------------------------------------- */
/* HELPERS: Date                                                              */
/* -------------------------------------------------------------------------- */
import moment from 'jalali-moment';
import { digits } from './number';

/**
 * Return today time
 * 
 * @returns {string}
 */
export const today = (): string => {
    const sundte = moment().locale('fa');
    const yeardte = sundte.format('YYYY');
    const monthdte = sundte.format('MMMM');
    const dtedte = sundte.format('DD');
    const daydte = sundte.format('dddd');

    return `امروز ${daydte} ${digits(dtedte, 'fa')} ${monthdte} ${digits(yeardte, 'fa')} است.`;
};
