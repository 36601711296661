import React from 'react';
import './index.scss';

interface props {
    active: boolean;
    children: any;
}

/**
 * Loading component implementation
 */
export const Loading: React.FC<props> = ({ active, children }) => (
    active ? (
        <div className="loader-container">
            <div className="spinner" />
        </div>
    ) : children
);
