/* eslint-disable camelcase */
import React, {
    useEffect, useCallback, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Button, Col, Row } from 'react-bootstrap';
import { Calendar } from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import weekends from 'react-multi-date-picker/plugins/highlight_weekends';
import { toJpeg } from 'html-to-image';
import { toast } from 'react-toastify';
import moment from 'jalali-moment';
import { $services } from '../../../services';
import { errorHandler } from '../../../services/helpers';
import { Navigation } from '../components/Navigation';
import { Loading } from '../../../components/Loading';
import { today as TodayTime } from '../../../helpers/date';
import { $store } from '../../../store';
import '../scss/dashboard.scss';

/**
 * General calendar implementation
 */
export const GeneralCalendar: React.FC = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [roomList, setRoomList] = useState([]);
    const [calendar, setCalendar] = useState<any>({});
    const [isDownloading, setDownloading] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const onButtonClick = useCallback(() => {
        setDownloading(true);
        if (ref.current === null) {
            setDownloading(false);
            return;
        }

        toJpeg(ref.current, {
            backgroundColor: '#FFFFFF',
            quality        : 1,
            type           : 'image/jpg',
            cacheBust      : true,
        }).then((dataUrl) => {
            const link = document.createElement('a');
            link.download = 'Calendar.jpg';
            setTimeout(() => {
                link.href = dataUrl;
                setTimeout(() => {
                    link.href = dataUrl;
                    link.click();
                    setDownloading(false);
                }, 1000);
            }, 2000);
        }).catch(() => {
            toast.error('We can\'t download this image 😥.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setDownloading(false);
        });
    }, [ref]);

    /**
     * Fetch Calendar date
     */
    const fetchCalendarDates = (
        id: string,
        month: string|number = moment().locale('fa').format('M'),
        year: string|number = moment().locale('fa').format('YYYY'),
    ) => {
        setUpdateLoading(true);
        $services.Calendar.list({
            room_id: id,
            month,
            year,
        }).then((response: any) => {
            setCalendar((state: any) => ({
                ...state,
                [`${id}`]: response.data,
            }));
        }).catch((error: any) => {
            errorHandler(error);
        }).finally(() => {
            setUpdateLoading(false);
        });
    };

    /**
     * Fetch Rooms Calendar date
     */
    const fetchRoomsCalendars = (
        rooms: any = roomList,
        month: string|number = moment().locale('fa').format('M'),
        year: string|number = moment().locale('fa').format('YYYY'),
    ) => {
        setCalendar({});
        rooms.forEach((room: any) => fetchCalendarDates(room.id, month, year));
    };

    /**
     * Fetch room list
     */
    const fetch = () => $services.Room.list().then((response: any) => {
        setRoomList(response.data);
        fetchRoomsCalendars(response.data);
    }).catch((error: any) => {
        errorHandler(error);
    }).finally(() => {
        setLoading(false);
    });

    useEffect(() => {
        const { isLoggedIn } = $store.getState().auth;
        if (isLoggedIn) {
            setLoading(true);
            fetch();
        } else {
            navigate('/auth/login');
        }
    }, []);

    return (
        <Loading active={ isLoading }>
            <div className="m-dashboard m-dashboard__general-calendar">
                <Helmet>
                    <title>Calendar</title>
                </Helmet>

                <header>
                    <span className="today-time">{ TodayTime() }</span>

                    <h3 className="m-dashboard__title">
                        General Calendar
                    </h3>
                </header>

                <Row className="justify-content-md-center mb-3">
                    <Col md="4">
                        <Button
                            variant="outline-primary"
                            className="w-100"
                            disabled={ isDownloading }
                            onClick={ () => onButtonClick() }
                        >
                            { isDownloading ? 'Loading…' : 'Download calendar' }
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col
                        lg="12"
                        className="m-dashboard__general-calendar__calendar"
                    >
                        <Calendar
                            ref={ ref }
                            className={ `${updateLoading ? 'loading' : ''}` }
                            calendar={ persian }
                            plugins={ [weekends()] }
                            locale={ persian_fa }
                            disabled={ updateLoading }
                            onMonthChange={ ({ month, year }) => {
                                fetchRoomsCalendars(roomList, month.number, year);
                            } }
                            mapDays={ ({
                                date, today, isSameDate, selectedDate,
                            }: any) => {
                                const props: any = {};

                                if (date.dayOfBeginning < today.dayOfBeginning) {
                                    props.disabled = true;
                                    props.style = {
                                        ...props.style,
                                        opacity: '0.3',
                                    };
                                }

                                if (isSameDate(date, selectedDate)) {
                                    props.style = {
                                        ...props.style,
                                        backgroundColor: '#FFFFFF',
                                        color          : '#424242',
                                    };
                                }

                                return {
                                    disabled: props.disabled,
                                    children: (
                                        <div style={ props.style }>
                                            <div style={{
                                                textAlign         : 'center',
                                                margin            : '0 3px 8px',
                                                borderBottom      : props.disabled ? '1px solid lightgray' : '1px solid black',
                                                paddingBottom     : '8px',
                                                fontSize          : '15px',
                                                textDecorationLine: props.disabled ? 'line-through' : 'none',
                                            }}
                                            >
                                                { date.format('D') }
                                            </div>
                                            <div style={{ textAlign: 'center' }}>
                                                {
                                                    roomList.map((room: any) => {
                                                        const reservationId = calendar[room.id]?.find((d: any) => d.date === date.day)?.reservation_id;

                                                        return (
                                                            <div
                                                                key={ `${room.id}-${date.format('D')}` }
                                                                className={ reservationId ? 'booked' : 'free' }
                                                                style={{ textAlign: 'center', marginBottom: '3px' }}
                                                            >
                                                                { room.name.split('/')[0] }
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    ),
                                };
                            } }
                        />
                    </Col>
                </Row>

                <Navigation />
            </div>
        </Loading>
    );
};
