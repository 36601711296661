/*-----------------------------------------------------------------
- Todo Services
-----------------------------------------------------------------*/
import { Resource } from '../index';

export default {
    /**
     * Todo - Create a new resource
     *
     * @param {...any} args
     * @returns {*}
     */
    create: (...args: any[]): any => Resource.post('todo/', args),

    /**
     * Todo - list
     *
     * @param {...any} args
     * @returns {*}
     */
    list: (...args: any[]): any => Resource.get('todo', args),

    /**
     * Todo - delete
     *
     * @param {...any} args
     * @returns {*}
     */
    delete: ({ id, ...args }: any): any => Resource.delete(`todo/${id}`, [args]),

};
